var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.$screen.width <= 576
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "container-mobile animated fadeIn pl-0 pr-0",
                  staticStyle: { top: "95px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "container-myProfile-mobile" },
                    [
                      _c(
                        "b-row",
                        [
                          _vm.menuActiveForMobile !== ""
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "px-0",
                                  attrs: { cols: !_vm.isFilmSingle ? 12 : 8 },
                                  on: {
                                    click: function ($event) {
                                      _vm.menuActiveForMobile = ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "back-with-title cursor-pointer",
                                      class: { "mt-16": _vm.$isPwa() },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "icon" },
                                        [
                                          _c(_vm.getIconLucide("ArrowLeft"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("h1", [
                                        _vm._v(_vm._s(_vm.pageMobileTitle)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "4",
                                offset: _vm.menuActiveForMobile === "" ? 4 : 0,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "float-right" },
                                [
                                  _vm.isFilmSingle
                                    ? _c("ShareInformation", {
                                        staticStyle: {
                                          margin: "16px 16px 16px 0",
                                        },
                                        attrs: {
                                          tag: "is-lucide",
                                          "btn-label": _vm.FormMSG(
                                            215,
                                            "Share"
                                          ),
                                          "variant-btn": "light",
                                          "title-modal": _vm.FormMSG(
                                            170,
                                            "Send your personal informations sheet"
                                          ),
                                          "send-a-copy": "",
                                          "msg-success": _vm.FormMSG(
                                            250,
                                            "Profile successfully sent"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "form",
                        { staticClass: "form", on: { submit: _vm.submitForm } },
                        [
                          _vm.menuActiveForMobile === ""
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "mt-3": _vm.menuActiveForMobile === "",
                                  },
                                },
                                [
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "User",
                                      title: _vm.FormMSG(1211, "Personal Data"),
                                      description: _vm.FormMSG(
                                        1212,
                                        "Update your basic information, address or emergency contact."
                                      ),
                                      path: "personal-data",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "ClipboardList",
                                      title: _vm.FormMSG(4, "Identification"),
                                      description: _vm.FormMSG(
                                        1214,
                                        "Update your identification numbers, bank information or family composition"
                                      ),
                                      path: "legal-information",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "Wallet",
                                      title: _vm.FormMSG(1306, "Finance"),
                                      path: "finance",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "MapPin",
                                      title: _vm.FormMSG(11, "Address"),
                                      path: "address",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "HeartPulse",
                                      title: _vm.FormMSG(1252, "Health"),
                                      path: "health",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "Users",
                                      title: _vm.FormMSG(13, "Family"),
                                      path: "family",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "Car",
                                      title: _vm.FormMSG(
                                        1226,
                                        "Personal vehicle"
                                      ),
                                      path: "personal-vehicle",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "List",
                                      title: _vm.FormMSG(16, "Other"),
                                      path: "other",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "Settings",
                                      title: _vm.FormMSG(1215, "Settings"),
                                      description: _vm.FormMSG(
                                        1216,
                                        "Change your password and communication language."
                                      ),
                                      path: "settings",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _vm.isDeterProject === true
                                    ? _c("list-menu", {
                                        attrs: {
                                          "icon-lucide": "UserCog",
                                          title: _vm.FormMSG(
                                            131,
                                            "Team session"
                                          ),
                                          description: _vm.FormMSG(
                                            1257,
                                            "Configure your team session."
                                          ),
                                          path: "team-session",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.menuActiveForMobile === "personal-data"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1182, "Basic Information")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    1240,
                                                    "Title"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-select",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.myProfile.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.title",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.menuTitle,
                                                    function (title, index) {
                                                      return _c(
                                                        "b-form-select-option",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            value: title.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                title.text
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: { for: "reference" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          this.FormMSG(
                                                            1,
                                                            "Reference"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    id: "reference",
                                                    placeholder: _vm.FormMSG(
                                                      1704,
                                                      "Personal number."
                                                    ),
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.reference,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "reference",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.reference",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "7" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    1041,
                                                    "Name"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1003,
                                                      "Enter Name"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.firstName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "firstName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.firstName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    40,
                                                    "Last Name"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1143,
                                                      "Enter Last Name"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.myProfile.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "maidenName",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1239,
                                                          " Maiden name (if different)"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    id: "maidenName",
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.maidenName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "maidenName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.maidenName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-col", { attrs: { cols: "5" } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "100px",
                                                width: "100px",
                                                "margin-left": "auto",
                                                "margin-right": "auto",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "container-img-mobile",
                                                  staticStyle: {
                                                    height: "100%",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _vm.myProfile.picture &&
                                                  _vm.myProfile.picture.length >
                                                    0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            height: "100%",
                                                            width: "100%",
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "96px",
                                                              height: "96px",
                                                            },
                                                            attrs: {
                                                              src: _vm.getImage(
                                                                _vm.myProfile
                                                                  .picture,
                                                                "picture"
                                                              ),
                                                            },
                                                          }),
                                                          _vm.myProfile
                                                            .picture &&
                                                          _vm.myProfile.picture
                                                            .length > 0
                                                            ? _c(
                                                                _vm.getIconLucide(
                                                                  "XCircle"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  staticClass:
                                                                    "cursor-pointer icon-remove-img-profile",
                                                                  staticStyle: {
                                                                    top: "-33px",
                                                                    right:
                                                                      "0px",
                                                                  },
                                                                  attrs: {
                                                                    size: 25,
                                                                    "stroke-width": 1.75,
                                                                    color:
                                                                      "#ea4e2c",
                                                                    id: "tooltip-remove-picture",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteImg(
                                                                          "picture"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm.myProfile
                                                            .picture &&
                                                          _vm.myProfile.picture
                                                            .length > 0
                                                            ? _c(
                                                                "b-tooltip",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "tooltip-remove-picture",
                                                                    triggers:
                                                                      "hover",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          255,
                                                                          "Remove picture"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass: "avatar",
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getIconLucide(
                                                              "User"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              staticClass:
                                                                "vertical-center",
                                                              attrs: {
                                                                fill: "#E2E6EB",
                                                                color:
                                                                  "#E2E6EB",
                                                                size: 45,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                  _c(
                                                    "MobileProfilePicCaptureBtn",
                                                    {
                                                      attrs: {
                                                        "parent-type": "user",
                                                        "crop-type": "avatar",
                                                        parentId:
                                                          _vm.myProfile.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeFieldPic(
                                                            "picture"
                                                          )
                                                        },
                                                        change:
                                                          _vm.handleImgsTaked,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm.useTask && !_vm.isFilmSingle
                                              ? _c(
                                                  "fieldset",
                                                  {
                                                    staticClass:
                                                      "scheduler-border",
                                                  },
                                                  [
                                                    _c(
                                                      "legend",
                                                      {
                                                        staticClass:
                                                          "scheduler-border",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                525,
                                                                "Color"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                            attrs: {
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c("verte", {
                                                              attrs: {
                                                                picker:
                                                                  "square",
                                                                model: "hex",
                                                                menuPosition:
                                                                  "top",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .color,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "color",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.color",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(42, "Email"),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.showChangeMailModal = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("Pencil", {
                                                            attrs: {
                                                              color: "#ffffff",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              680477981
                                            ),
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                state:
                                                  _vm.myProfile.email &&
                                                  _vm.myProfile.email.length >
                                                    0,
                                                value: _vm.myProfile.email,
                                                type: "text",
                                                id: "email",
                                                placeholder: _vm.FormMSG(
                                                  1102,
                                                  "Enter your email"
                                                ),
                                                disabled: "",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(44, "Phone"),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.showChangePhoneModal = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("Pencil", {
                                                            attrs: {
                                                              color: "#ffffff",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2770077608
                                            ),
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                value: _vm.myProfile.phone,
                                                type: "text",
                                                id: "phone",
                                                disabled: "",
                                                placeholder: _vm.FormMSG(
                                                  45,
                                                  "Enter your phone number"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    46,
                                                    "Nationality"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      46,
                                                      "Nationality"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.nationality,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "nationality",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.nationality",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    1305,
                                                    "Language"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.optionsForLanguages,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.language,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "language",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.language",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    48,
                                                    "Birthdate"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    value: _vm.displayBirthDate,
                                                    type: "date",
                                                    placeholder: _vm.FormMSG(
                                                      1205,
                                                      "DD/MM/YYYY"
                                                    ),
                                                  },
                                                  on: {
                                                    input: _vm.updateBirthDate,
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    1241,
                                                    "Country of birth"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1315,
                                                      "Enter your country of ..."
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .birthCountry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "birthCountry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.birthCountry",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    1152,
                                                    "City of Birth"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1153,
                                                      "Enter City of Birth"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .placeOfBirth,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "placeOfBirth",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.placeOfBirth",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    1154,
                                                    "Zip Code (City of Birth)"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1155,
                                                      "Enter Zip Code"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .placeOfBirthZip,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "placeOfBirthZip",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.placeOfBirthZip",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("b-form-group", {
                                      staticClass: "gender-group",
                                      attrs: {
                                        label: _vm.FormMSG(164, "Gender"),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ ariaDescribedby }) {
                                              return [
                                                _c(
                                                  "b-form-radio-group",
                                                  {
                                                    attrs: {
                                                      "aria-describedby":
                                                        ariaDescribedby,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.gender,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "gender",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.gender",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.menuGenders,
                                                        function (
                                                          gender,
                                                          index
                                                        ) {
                                                          return gender.value !==
                                                            null
                                                            ? _c(
                                                                "b-form-radio",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    value:
                                                                      gender.value,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        gender.text
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1201312255
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "legal-information"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "legal-information" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            1187,
                                            "Identification Numbers"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            55,
                                            "National Registration Number"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              56,
                                              "Enter your National Registration Number "
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile
                                                .nationalRegistryNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "nationalRegistryNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.nationalRegistryNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            57,
                                            "Company Number"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              58,
                                              "Enter your Company official Number "
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.companyNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "companyNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.companyNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "conge_spectacles_id",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1242,
                                                  '"Congés spectacles" ID'
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "conge_spectacles_id",
                                            placeholder: _vm.FormMSG(
                                              1243,
                                              "Enter your conges spectacles id"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.congesSpectacle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "congesSpectacle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.congesSpectacle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "idNumber" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(216, "ID Name")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "idNumber",
                                            placeholder: _vm.FormMSG(
                                              217,
                                              "Enter your ID Name ..."
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.idName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "idName",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.idName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(32, "ID Number"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "idNumber",
                                            placeholder: _vm.FormMSG(
                                              33,
                                              "Enter your ID Number ..."
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.idNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "idNumber",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.idNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            166,
                                            "Driver licence"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              167,
                                              "Enter your driver license"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.driverLicense,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "driverLicense",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.driverLicense",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1314,
                                                "Driver licence Number"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1316,
                                              "Enter your driver license number"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.driverLicenseNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "driverLicenseNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.driverLicenseNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "signatureId" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  223,
                                                  "My signature"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "ml-0" },
                                          [
                                            _vm.checkFile(
                                              _vm.myProfile.signatureId
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.onOpenUrl(
                                                          _vm.getImage(
                                                            _vm.myProfile
                                                              .signatureId,
                                                            "signatureId"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "file-earmark-pdf-fill",
                                                        "font-scale": 1.6,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "b-img",
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        rounded: "circle",
                                                        alt: "Signature ID",
                                                        src: _vm.getImage(
                                                          _vm.myProfile
                                                            .signatureId,
                                                          "signatureId"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showImage(
                                                            _vm.myProfile
                                                              .signatureId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "b-img",
                                                    _vm.imgPropSignatureId,
                                                    false
                                                  )
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex ml-3 justify-content-center align-items-center",
                                              },
                                              [
                                                _c(
                                                  "MobileProfilePicCaptureBtn",
                                                  {
                                                    attrs: {
                                                      "parent-type": "user",
                                                      id: "pictureID",
                                                      parentId:
                                                        _vm.myProfile.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeFieldPic(
                                                          "signatureId"
                                                        )
                                                      },
                                                      change:
                                                        _vm.handleImgsTaked,
                                                    },
                                                  }
                                                ),
                                                _vm.myProfile.signatureId &&
                                                _vm.myProfile.signatureId
                                                  .length > 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn-transparent pl-0 ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.deleteImg(
                                                              "signatureId"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Trash2"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              size: 24,
                                                              "stroke-width": 1.5,
                                                              color: "#ea4e2c",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: { for: "pictureId" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          218,
                                                          "ID picture - Front"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile.pictureId
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .pictureId,
                                                                    "pictureId"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded:
                                                                  "circle",
                                                                alt: "ID picture - front",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .pictureId,
                                                                  "pictureId"
                                                                ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropPictureId,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "pictureID",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "pictureId"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .pictureId &&
                                                        _vm.myProfile.pictureId
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureId"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "pictureIdVerso",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          219,
                                                          "ID picture - Back"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile
                                                        .pictureIdVerso
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .pictureIdVerso,
                                                                    "pictureIdVerso"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded:
                                                                  "circle",
                                                                alt: "ID picture - Back",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .pictureIdVerso,
                                                                  "pictureIdVerso"
                                                                ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureIdVerso
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropPictureIdVerso,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "pictureIdVerso",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "pictureIdVerso"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .pictureIdVerso &&
                                                        _vm.myProfile
                                                          .pictureIdVerso
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureIdVerso"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "pictureDriverLicense",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          221,
                                                          "Driver license picture - Front"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile
                                                        .pictureDriverLicense
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .pictureDriverLicense,
                                                                    "pictureDriverLicense"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded: "",
                                                                alt: "Driver license picture - Front",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .pictureDriverLicense,
                                                                  "pictureDriverLicense"
                                                                ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureDriverLicense
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropPictureDriverLicense,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "pictureDriverLicense",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "pictureDriverLicense"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .pictureDriverLicense &&
                                                        _vm.myProfile
                                                          .pictureDriverLicense
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureDriverLicense"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "pictureDriverLicenseVerso",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          222,
                                                          "Driver license picture - Back"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile
                                                        .pictureDriverLicenseVerso
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .pictureDriverLicenseVerso,
                                                                    "pictureDriverLicenseVerso"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded: "",
                                                                alt: "Driver license picture - Back",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .pictureDriverLicenseVerso,
                                                                  "pictureDriverLicenseVerso"
                                                                ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureDriverLicenseVerso
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropPictureIdVerso,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "pictureDriverLicenseVerso",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "pictureDriverLicenseVerso"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .pictureDriverLicenseVerso &&
                                                        _vm.myProfile
                                                          .pictureDriverLicenseVerso
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureDriverLicenseVerso"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isPme && _vm.myProfile.vehicule
                                      ? _c("div", [
                                          _c("div", { staticClass: "divider" }),
                                          _c("h2", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(1227, "Vehicle")
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "switch-group" },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1228,
                                                      "Name"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1229,
                                                        "Vehicle name ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1230,
                                                      "Brand"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1231,
                                                        "Vehicle brand ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .brand,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "brand",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.brand",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1232,
                                                      "Model"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1233,
                                                        "Vehicle model ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.model",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1234,
                                                      "Number plate"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1235,
                                                        "Your number plate ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .numberPlate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "numberPlate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.numberPlate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1236,
                                                      "Type"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options: _vm.vehicleTypes,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.type",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1237,
                                                      "Energy"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.vehicleEnergies,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .energy,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "energy",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.energy",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1238,
                                                          "Kg CO2 per"
                                                        )
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.distanceUnit)
                                                    ),
                                                  ]),
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .kgCoTwoPerKm,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "kgCoTwoPerKm",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.kgCoTwoPerKm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "finance"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1188, "Bank Information")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        class: {
                                          "is-warning":
                                            _vm.userBankWarning.iban,
                                        },
                                        attrs: {
                                          label: _vm.FormMSG(49, "IBAN"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "is-warning":
                                              _vm.userBankWarning.iban,
                                          },
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1189,
                                              "Enter IBAN"
                                            ),
                                          },
                                          on: {
                                            input: _vm.handleSupplierIbanChange,
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.iban,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "iban",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.iban",
                                          },
                                        }),
                                        _vm.userBankWarning.iban
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "warning-feedback",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        97986,
                                                        "IBAN seems not valid, please make sure it's corrrect"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        class: {
                                          "is-warning": _vm.userBankWarning.bic,
                                        },
                                        attrs: {
                                          label: _vm.FormMSG(51, "BIC"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1190,
                                              "Enter BIC"
                                            ),
                                          },
                                          on: {
                                            input: _vm.handleSupplierBicChange,
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.bic,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "bic",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.bic",
                                          },
                                        }),
                                        _vm.userBankWarning.bic
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "warning-feedback",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        909097986,
                                                        "BIC seems not valid, please make sure it's corrrect"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("label", { attrs: { for: "bic" } }, [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                1245,
                                                "Bank account owner name"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "bic",
                                            placeholder: this.FormMSG(
                                              1246,
                                              "Enter your bank account owner name"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile
                                                .bankAccountOwnerName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "bankAccountOwnerName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.bankAccountOwnerName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "bankDetailDocument",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1247,
                                                  "Bank detail document"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "ml-0" },
                                          [
                                            _vm.checkFile(
                                              _vm.myProfile.bankDetailXid
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.onOpenUrl(
                                                          _vm.getImage(
                                                            _vm.myProfile
                                                              .bankDetailXid,
                                                            "bankDetailXid"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "file-earmark-pdf-fill",
                                                        "font-scale": 1.6,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "b-img",
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        rounded: "",
                                                        alt: "Bank detail document",
                                                        src: _vm.getImage(
                                                          _vm.myProfile
                                                            .bankDetailXid,
                                                          "bankDetailXid"
                                                        ),
                                                        title: _vm.FormMSG(
                                                          254,
                                                          "View Image"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showImage(
                                                            _vm.myProfile
                                                              .bankDetailXid
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "b-img",
                                                    _vm.imgPropSignatureId,
                                                    false
                                                  )
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex ml-3 justify-content-center align-items-center",
                                              },
                                              [
                                                _c(
                                                  "MobileProfilePicCaptureBtn",
                                                  {
                                                    attrs: {
                                                      "parent-type": "user",
                                                      id: "pictureRib",
                                                      parentId:
                                                        _vm.myProfile.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeFieldPic(
                                                          "bankDetailXid"
                                                        )
                                                      },
                                                      change:
                                                        _vm.handleImgsTaked,
                                                    },
                                                  }
                                                ),
                                                _vm.myProfile.bankDetailXid &&
                                                _vm.myProfile.bankDetailXid
                                                  .length > 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn-transparent pl-0 ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.deleteImg(
                                                              "bankDetailXid"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Trash2"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              size: 24,
                                                              "stroke-width": 1.5,
                                                              color: "#ea4e2c",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("h2", [
                                      _vm._v(_vm._s(_vm.FormMSG(260, "Taxes"))),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1310,
                                            "Taxes percentage"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              directives: [
                                                {
                                                  name: "limit-100",
                                                  rawName: "v-limit-100",
                                                },
                                              ],
                                              attrs: {
                                                type: "number",
                                                step: "1",
                                                min: "0",
                                                max: "100",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.taxesPercentage,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "taxesPercentage",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.taxesPercentage",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  [
                                                    _c(
                                                      _vm.getIconLucide(
                                                        "Percent"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#06263E",
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("label", { attrs: { for: "bic" } }, [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                1248,
                                                "Tax declaration country"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "taxCountry",
                                            placeholder: this.FormMSG(
                                              1249,
                                              "Enter your tax declaration country"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.taxCountry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "taxCountry",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.taxCountry",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "address"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1308, "Legal Address")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "country" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(106, "Country")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("v-select", {
                                          attrs: {
                                            clearable: false,
                                            options: _vm.countries,
                                            label: "text",
                                            reduce: (option) => option.value,
                                          },
                                          on: { input: _vm.getCountry },
                                          model: {
                                            value: _vm.countryKey,
                                            callback: function ($$v) {
                                              _vm.countryKey = $$v
                                            },
                                            expression: "countryKey",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("google-auto-complete", {
                                          ref: "google-search-address",
                                          attrs: {
                                            label: _vm.FormMSG(
                                              12,
                                              "Internet search"
                                            ),
                                            country: _vm.countryCode,
                                          },
                                          on: {
                                            placechanged: (item) =>
                                              _vm.handleAutoComplete(
                                                item,
                                                "legalAddress"
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(100, "Street"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1156,
                                              "Enter Street Name"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.address.street,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile.address,
                                                "street",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.address.street",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(101, "Number"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1157,
                                              "Enter Number"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.address.number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile.address,
                                                "number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.address.number",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1170,
                                            "State/Region"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1171,
                                              "Enter State/Region"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.address.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile.address,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.address.state",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(103, "City"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1172,
                                              "Enter City"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.address.city,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile.address,
                                                "city",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.address.city",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(1167, "ZIP Code"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1168,
                                              "Enter ZIP Code"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.address.zip,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile.address,
                                                "zip",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.address.zip",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(102, "Box"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1169,
                                              "Enter Box Number"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.address.box,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile.address,
                                                "box",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.address.box",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "personal-data mb-4" },
                                  [
                                    _c(
                                      "b-collapse",
                                      {
                                        model: {
                                          value: _vm.isShowPostalAddress,
                                          callback: function ($$v) {
                                            _vm.isShowPostalAddress = $$v
                                          },
                                          expression: "isShowPostalAddress",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1312,
                                                "Postal address"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "country" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(106, "Country")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("v-select", {
                                              attrs: {
                                                clearable: false,
                                                options: _vm.countries,
                                                label: "text",
                                                reduce: (option) =>
                                                  option.value,
                                              },
                                              on: {
                                                input:
                                                  _vm.getCountryPostalAddress,
                                              },
                                              model: {
                                                value:
                                                  _vm.countryKeyPostalAddress,
                                                callback: function ($$v) {
                                                  _vm.countryKeyPostalAddress =
                                                    $$v
                                                },
                                                expression:
                                                  "countryKeyPostalAddress",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("google-auto-complete", {
                                              ref: "google-search-address",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  12,
                                                  "Internet search"
                                                ),
                                                country:
                                                  _vm.countryCodePostalAddress,
                                              },
                                              on: {
                                                placechanged: (item) =>
                                                  _vm.handleAutoComplete(
                                                    item,
                                                    "postalAddress"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "address" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(100, "Street")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "street",
                                                placeholder: _vm.FormMSG(
                                                  1103,
                                                  "Enter your street"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .street,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "street",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.street",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "number" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(101, "Number")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "number",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "state" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(105, "State")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "state",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.state",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "city" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(103, "City")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "city",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.city",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "zip" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(
                                                      104,
                                                      "Postal Code"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "zip",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .zip,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "zip",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.zip",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "box" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(102, "Box")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "box",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .box,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "box",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.box",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("b-row", [
                                      _c(
                                        "div",
                                        { staticClass: "ml-3" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto",
                                              staticStyle: {
                                                height: "40px",
                                                "border-radius": "0.5rem",
                                                "background-color": "#fff",
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                size: "sm",
                                                variant: _vm.isShowPostalAddress
                                                  ? "outline-danger"
                                                  : "outline-secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.isShowPostalAddress =
                                                    !_vm.isShowPostalAddress
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-center justify-content-center btn-address",
                                                  staticStyle: {
                                                    color: "#06263e",
                                                  },
                                                },
                                                [
                                                  _vm.isShowPostalAddress
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.X_CLEAR
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .X_CLEAR
                                                                    .color,
                                                                size: 22,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1313,
                                                                  "Close"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "PlusCircle"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "#47C7BF",
                                                                size: 22,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1251,
                                                                  "Add postal address (if different)"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "health"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "health" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            54,
                                            "Social Security Number"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "is-warning":
                                              _vm.isSocSecNumberWarning,
                                          },
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              154,
                                              "Enter your Social Security Number"
                                            ),
                                          },
                                          on: {
                                            input: _vm.handleSocSecNumberChange,
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.socSecNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "socSecNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.socSecNumber",
                                          },
                                        }),
                                        _vm.isSocSecNumberWarning
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "warning-feedback",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        8979997,
                                                        "The social security number seems not valid"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "socialSecurityCenter",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  1700,
                                                  "Social security center"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "socialSecurityCenter",
                                            placeholder: this.FormMSG(
                                              1258,
                                              "Enter your social security center"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile
                                                .socialSecurityCenter,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "socialSecurityCenter",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.socialSecurityCenter",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1259,
                                            "Social security center address"
                                          ),
                                        },
                                      },
                                      [
                                        _c("v-address", {
                                          attrs: {
                                            params: {
                                              title:
                                                "Social security center address",
                                            },
                                            "address-id":
                                              _vm.myProfile
                                                .socialSecurityCenterAddressId,
                                          },
                                          on: {
                                            change:
                                              _vm.handleDynamicAddressChange,
                                            delete:
                                              _vm.handleDynamicAddressDelete,
                                            "address-maplink-change":
                                              _vm.handleAddressMapLinkChange,
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "socialSecurityCardXid",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1255,
                                                          "Social security card"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile
                                                        .socialSecurityCardXid
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .socialSecurityCardXid,
                                                                    "socialSecurityCardXid"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded: "",
                                                                alt: "socialSecurityCardXid",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .socialSecurityCardXid,
                                                                  "socialSecurityCardXid"
                                                                ),
                                                                title:
                                                                  _vm.FormMSG(
                                                                    254,
                                                                    "View Image"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .socialSecurityCardXid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropSignatureId,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "pictureRib",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "socialSecurityCardXid"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .socialSecurityCardXid &&
                                                        _vm.myProfile
                                                          .socialSecurityCardXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "socialSecurityCardXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "insuranceCertificate",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1256,
                                                          "Insurance certificate"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile
                                                        .insuranceCertificateXid
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .insuranceCertificateXid,
                                                                    "insuranceCertificateXid"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded: "",
                                                                alt: "insuranceCertificate",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .insuranceCertificateXid,
                                                                  "insuranceCertificateXid"
                                                                ),
                                                                title:
                                                                  _vm.FormMSG(
                                                                    254,
                                                                    "View Image"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .insuranceCertificateXid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropSignatureId,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "insuranceCertificateXid",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "insuranceCertificateXid"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .insuranceCertificateXid &&
                                                        _vm.myProfile
                                                          .insuranceCertificateXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "insuranceCertificateXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1260,
                                                "Last medical visit date"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            value:
                                              _vm.displayDateLastMedicalVisit,
                                            placeholder: _vm.FormMSG(
                                              1295,
                                              "DD/MM/YYYY"
                                            ),
                                          },
                                          on: {
                                            input: (e) =>
                                              _vm.updateDate(
                                                e,
                                                "lastMedicalVisit"
                                              ),
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1262,
                                                "Medical visit validity"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            value:
                                              _vm.displayDateMedicalVisitValidity,
                                            placeholder: _vm.FormMSG(
                                              1295,
                                              "DD/MM/YYYY"
                                            ),
                                          },
                                          on: {
                                            input: (e) =>
                                              _vm.updateDate(
                                                e,
                                                "medicalVisitValidity"
                                              ),
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "MedicalVisitDocument",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1264,
                                                  "Medical visit document"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "ml-0" },
                                          [
                                            _vm.checkFile(
                                              _vm.myProfile
                                                .medicalVisitDocumentXid
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.onOpenUrl(
                                                          _vm.getImage(
                                                            _vm.myProfile
                                                              .medicalVisitDocumentXid,
                                                            "medicalVisitDocumentXid"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "file-earmark-pdf-fill",
                                                        "font-scale": 1.6,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "b-img",
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        rounded: "",
                                                        alt: "MedicalVisitDocument",
                                                        src: _vm.getImage(
                                                          _vm.myProfile
                                                            .medicalVisitDocumentXid,
                                                          "medicalVisitDocumentXid"
                                                        ),
                                                        title: _vm.FormMSG(
                                                          254,
                                                          "View Image"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showImage(
                                                            _vm.myProfile
                                                              .medicalVisitDocumentXid
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "b-img",
                                                    _vm.imgPropSignatureId,
                                                    false
                                                  )
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex ml-3 justify-content-center align-items-center",
                                              },
                                              [
                                                _c(
                                                  "MobileProfilePicCaptureBtn",
                                                  {
                                                    attrs: {
                                                      "parent-type": "user",
                                                      id: "medicalVisitDocumentXid",
                                                      parentId:
                                                        _vm.myProfile.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeFieldPic(
                                                          "medicalVisitDocumentXid"
                                                        )
                                                      },
                                                      change:
                                                        _vm.handleImgsTaked,
                                                    },
                                                  }
                                                ),
                                                _vm.myProfile
                                                  .medicalVisitDocumentXid &&
                                                _vm.myProfile
                                                  .medicalVisitDocumentXid
                                                  .length > 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn-transparent pl-0 ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.deleteImg(
                                                              "medicalVisitDocumentXid"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Trash2"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              size: 24,
                                                              "stroke-width": 1.5,
                                                              color: "#ea4e2c",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "text-start" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1265,
                                                "Worker with a disability"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass:
                                              "d-flex justify-content-start",
                                            attrs: { size: "lg", switch: "" },
                                            model: {
                                              value: _vm.myProfile.disabled,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.myProfile,
                                                  "disabled",
                                                  $$v
                                                )
                                              },
                                              expression: "myProfile.disabled",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-redired" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.myProfile.disabled
                                                      ? _vm.FormMSG(1276, "Yes")
                                                      : _vm.FormMSG(1277, "No")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.myProfile.disabled
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1266,
                                                            "Disability notification date"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "date",
                                                        value:
                                                          _vm.displayDateDisabilityNotification,
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1295,
                                                            "DD/MM/YYYY"
                                                          ),
                                                      },
                                                      on: {
                                                        input: (e) =>
                                                          _vm.updateDate(
                                                            e,
                                                            "disabilityNotification"
                                                          ),
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "type" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1268,
                                                              "Type"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "type",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1269,
                                                            "Enter type"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .disabilityType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "disabilityType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.disabilityType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "status",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1270,
                                                              "Status"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "status",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1271,
                                                            "Enter status"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .disabilityStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "disabilityStatus",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.disabilityStatus",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1272,
                                                        "Invalidity"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-select",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .invalidity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "invalidity",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.invalidity",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.menuInvalidity,
                                                        function (
                                                          gender,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "b-form-select-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                value:
                                                                  gender.value,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    gender.text
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  { staticClass: "text-start" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1273,
                                                            "RQTH"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start",
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          switch: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile.rqth,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "rqth",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.rqth",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-redired",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.myProfile
                                                                  .rqth
                                                                  ? _vm.FormMSG(
                                                                      1276,
                                                                      "Yes"
                                                                    )
                                                                  : _vm.FormMSG(
                                                                      1277,
                                                                      "No"
                                                                    )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1274,
                                                        "Invalidity percentage"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "limit-100",
                                                              rawName:
                                                                "v-limit-100",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            max: "100",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .invalidityPourcentage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "invalidityPourcentage",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.invalidityPourcentage",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              {
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#00a09c",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Percent"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        "#ffffff",
                                                                      size: 16,
                                                                      "stroke-width": 3.5,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "text-start" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(25, "Have Allergies?")
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass:
                                              "d-flex justify-content-start",
                                            attrs: { size: "lg", switch: "" },
                                            model: {
                                              value: _vm.haveAllergies,
                                              callback: function ($$v) {
                                                _vm.haveAllergies = $$v
                                              },
                                              expression: "haveAllergies",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-redired" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.haveAllergies
                                                      ? _vm.FormMSG(1276, "Yes")
                                                      : _vm.FormMSG(1277, "No")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.haveAllergies
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        26,
                                                        "Allergies details"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    type: "textarea",
                                                    rows: "2",
                                                    placeholder: _vm.FormMSG(
                                                      6,
                                                      "Enter allergies details..."
                                                    ),
                                                    autocomplete: "off",
                                                    disabled:
                                                      _vm.haveAllergies ===
                                                      false,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.allergies,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "allergies",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.allergies",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "mt-4" }, [
                                      _vm._v(" "),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "family"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(14, "Family composition")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            53,
                                            "Civil status"
                                          ),
                                          "label-for": "optionsForCivilStatus",
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.optionsForCivilStatus,
                                            id: "optionsForCivilStatus",
                                          },
                                          model: {
                                            value: _vm.myProfile.civilStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "civilStatus",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.civilStatus",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.myProfile.civilStatus === 3
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        174,
                                                        "Spouse first name"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      176,
                                                      "Enter your Spouse first name "
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .spouseFirstName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "spouseFirstName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.spouseFirstName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        172,
                                                        "Spouse name"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      173,
                                                      "Enter your Spouse name "
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.spouseName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "spouseName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.spouseName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        177,
                                                        "Spouse birth date"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    value:
                                                      _vm.displaySpouseBirthDate,
                                                    placeholder: _vm.FormMSG(
                                                      178,
                                                      "Enter your Spouse birth date "
                                                    ),
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.updateSpouseBirthDate,
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              { staticClass: "text-start" },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        181,
                                                        "Spouse has salary"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start",
                                                    attrs: {
                                                      size: "lg",
                                                      switch: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile
                                                          .spouseHasSalary,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "spouseHasSalary",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.spouseHasSalary",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-redired",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.myProfile
                                                              .spouseHasSalary
                                                              ? _vm.FormMSG(
                                                                  1276,
                                                                  "Yes"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1277,
                                                                  "No"
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        179,
                                                        "Spouse profession"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      180,
                                                      "Enter your Spouse profession "
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .spouseProfession,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "spouseProfession",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.spouseProfession",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c(
                                          "b-form-group",
                                          { staticClass: "text-start" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1192,
                                                    "Do you have children ?"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  size: "lg",
                                                  switch: "",
                                                },
                                                model: {
                                                  value: _vm.haveChild,
                                                  callback: function ($$v) {
                                                    _vm.haveChild = $$v
                                                  },
                                                  expression: "haveChild",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-redired",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.haveChild
                                                          ? _vm.FormMSG(
                                                              1276,
                                                              "Yes"
                                                            )
                                                          : _vm.FormMSG(
                                                              1277,
                                                              "No"
                                                            )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.haveChild
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "px-0 d-flex justify-content-start align-items-center",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn mr-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.decrementValue(
                                                              "numberOfDependentChild"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Minus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "little-input mt-2",
                                                      attrs: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDependentChild,
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn ml-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.incrementValue(
                                                              "numberOfDependentChild"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Plus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                    staticStyle: {
                                                      "margin-top": "0.85rem",
                                                    },
                                                    attrs: { cols: "8" },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "m-0 pl-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              183,
                                                              "Number of dependent child"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mt-3 d-flex flex-row align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "px-0 d-flex justify-content-start align-items-center",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn mr-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.decrementValue(
                                                              "numberOfDisabledChild"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Minus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "little-input mt-2",
                                                      attrs: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDisabledChild,
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn ml-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.incrementValue(
                                                              "numberOfDisabledChild"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Plus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                    staticStyle: {
                                                      "margin-top": "0.85rem",
                                                    },
                                                    attrs: { cols: "8" },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "m-0 pl-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              184,
                                                              "Number of disabled child"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c(
                                          "b-form-group",
                                          { staticClass: "text-start" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1193,
                                                    "Do you have other dependents ?"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  size: "lg",
                                                  switch: "",
                                                },
                                                model: {
                                                  value: _vm.haveDependents,
                                                  callback: function ($$v) {
                                                    _vm.haveDependents = $$v
                                                  },
                                                  expression: "haveDependents",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-redired",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.haveDependents
                                                          ? _vm.FormMSG(
                                                              1276,
                                                              "Yes"
                                                            )
                                                          : _vm.FormMSG(
                                                              1277,
                                                              "No"
                                                            )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.haveDependents
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "px-0 d-flex justify-content-start align-items-center",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn mr-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.decrementValue(
                                                              "numberOfDependentPerson"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Minus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "little-input mt-2",
                                                      attrs: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDependentPerson,
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn ml-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.incrementValue(
                                                              "numberOfDependentPerson"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Plus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                    staticStyle: {
                                                      "margin-top": "0.85rem",
                                                    },
                                                    attrs: { cols: "8" },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "m-0 pl-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1221,
                                                              "Number of dependent persons"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mt-3 d-flex flex-row align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "px-0 d-flex justify-content-start align-items-center",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn mr-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.decrementValue(
                                                              "numberOfDisabledPerson"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Minus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "little-input mt-2",
                                                      attrs: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDisabledPerson,
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "little-btn ml-2 px-1",
                                                        attrs: {
                                                          variant: "light",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.incrementValue(
                                                              "numberOfDisabledPerson"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Plus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "#06263EA3",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                    staticStyle: {
                                                      "margin-top": "0.85rem",
                                                    },
                                                    attrs: { cols: "8" },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "m-0 pl-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1222,
                                                              "Number of disabled persons"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "divider mr-4 ml-4" }),
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1176, "Emergency Contact")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1177,
                                            "Contact Name"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1178,
                                              "Enter Contact Name"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.personToContact,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "personToContact",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.personToContact",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1179,
                                            "Contact Phone Number"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.FormMSG(
                                              1180,
                                              "Enter Contact Phone Number"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile
                                                .personToContactPhone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "personToContactPhone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.personToContactPhone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "mt-4" }, [
                                  _vm._v(" "),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "personal-vehicle"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1226, "Personal vehicle")
                                        )
                                      ),
                                    ]),
                                    _c("vehicule-form", {
                                      attrs: {
                                        "vehicule-data": _vm.myProfile.vehicule,
                                      },
                                      on: {
                                        "vehicule-form:change":
                                          _vm.handleVehiculeFormChange,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "other"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(_vm._s(_vm.FormMSG(16, "Other"))),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "text-start" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                18,
                                                "Show my email in crew list"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass:
                                              "d-flex justify-content-start",
                                            attrs: { size: "lg", switch: "" },
                                            model: {
                                              value:
                                                _vm.myProfile.showMyEmailInCrew,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.myProfile,
                                                  "showMyEmailInCrew",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "myProfile.showMyEmailInCrew",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-redired" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.myProfile
                                                      .showMyEmailInCrew
                                                      ? _vm.FormMSG(1276, "Yes")
                                                      : _vm.FormMSG(1277, "No")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "text-start" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                19,
                                                "Show my phone in crew list"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass:
                                              "d-flex justify-content-start",
                                            attrs: { size: "lg", switch: "" },
                                            model: {
                                              value:
                                                _vm.myProfile.showMyPhoneInCrew,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.myProfile,
                                                  "showMyPhoneInCrew",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "myProfile.showMyPhoneInCrew",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-redired" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.myProfile
                                                      .showMyPhoneInCrew
                                                      ? _vm.FormMSG(1276, "Yes")
                                                      : _vm.FormMSG(1277, "No")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "divider mr-4 ml-4" }),
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1280, "Loyalty cards")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1281,
                                                              "SNCF loyalty card"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-fin",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1282,
                                                                "(Type + number)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3313594624
                                        ),
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1283,
                                              "Enter your sncf loyalty card"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.trainLoyaltyCard,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "trainLoyaltyCard",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.trainLoyaltyCard",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1284,
                                                              "SNCF discount card"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-fin",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1282,
                                                                "(Type + number)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2363352930
                                        ),
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1285,
                                              "Enter your sncf discount card"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.trainDiscountCard,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "trainDiscountCard",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.trainDiscountCard",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1286,
                                                              "Flying blue card"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-fin",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1287,
                                                                "(Number)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3995662622
                                        ),
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1288,
                                              "Enter your flying blue card"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.flyingLoyaltyCard,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "flyingLoyaltyCard",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.flyingLoyaltyCard",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1289,
                                                              "Other airline card"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-fin",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1287,
                                                                "(Number)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          115054700
                                        ),
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1288,
                                              "Enter your other airline card"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile.otherAirlineCard,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "otherAirlineCard",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.otherAirlineCard",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "divider mr-4 ml-4" }),
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                1290,
                                                "Foreign worker"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-fin" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1309,
                                                  "(section to fill only if you're a foreign worker)"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1292,
                                            "Residence permit number"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1293,
                                              "Enter your residence permit number"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile
                                                .residencePermitNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "residencePermitNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.residencePermitNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1294,
                                            "Residence permit validity end"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            value:
                                              _vm.displayDateResidencePermitEndValidity,
                                            placeholder: _vm.FormMSG(
                                              1295,
                                              "DD/MM/YYYY"
                                            ),
                                          },
                                          on: {
                                            input: (e) =>
                                              _vm.updateDate(
                                                e,
                                                "residencePermitEndValidity"
                                              ),
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "residentPermitXid",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1296,
                                                          "Resident permit"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile
                                                        .residentPermitXid
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .residentPermitXid,
                                                                    "residentPermitXid"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded: "",
                                                                alt: "residentPermitXid",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .residentPermitXid,
                                                                  "residentPermitXid"
                                                                ),
                                                                title:
                                                                  _vm.FormMSG(
                                                                    254,
                                                                    "View Image"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .residentPermitXid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropSignatureId,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "residentPermitXid",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "residentPermitXid"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .residentPermitXid &&
                                                        _vm.myProfile
                                                          .residentPermitXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "residentPermitXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "workCardXid",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1297,
                                                          "Work card"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _vm.checkFile(
                                                      _vm.myProfile.workCardXid
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.onOpenUrl(
                                                                  _vm.getImage(
                                                                    _vm
                                                                      .myProfile
                                                                      .workCardXid,
                                                                    "workCardXid"
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("b-icon", {
                                                              attrs: {
                                                                icon: "file-earmark-pdf-fill",
                                                                "font-scale": 1.6,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "b-img",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              attrs: {
                                                                rounded: "",
                                                                alt: "workCardXid",
                                                                src: _vm.getImage(
                                                                  _vm.myProfile
                                                                    .workCardXid,
                                                                  "workCardXid"
                                                                ),
                                                                title:
                                                                  _vm.FormMSG(
                                                                    254,
                                                                    "View Image"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .workCardXid
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "b-img",
                                                            _vm.imgPropSignatureId,
                                                            false
                                                          )
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex ml-3 justify-content-center align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              id: "workCardXid",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "workCardXid"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                        _vm.myProfile
                                                          .workCardXid &&
                                                        _vm.myProfile
                                                          .workCardXid.length >
                                                          0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "workCardXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "divider mr-4 ml-4" }),
                                _c("div", { staticClass: "mt-1" }, [
                                  _vm._v(" "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(24, "Miscellanous"))
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(1298, "Pseudonym"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1299,
                                              "Enter your pseudonym"
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.myProfile.pseudonym,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "pseudonym",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.pseudonym",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1300,
                                            "Generic mention professional association"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.FormMSG(
                                              1301,
                                              "Enter your generic mention ..."
                                            ),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.myProfile
                                                .professionalAssociation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "professionalAssociation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "myProfile.professionalAssociation",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(1705, "Retirement")
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-group",
                                          { staticClass: "text-start" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(1275, "Retired ?")
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  size: "lg",
                                                  value: true,
                                                  switch: "",
                                                },
                                                model: {
                                                  value: _vm.myProfile.retired,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.myProfile,
                                                      "retired",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "myProfile.retired",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-redired",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.myProfile.retired
                                                          ? _vm.FormMSG(
                                                              1276,
                                                              "Yes"
                                                            )
                                                          : _vm.FormMSG(
                                                              1277,
                                                              "No"
                                                            )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.myProfile.retired
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1278,
                                                        "Retirement date"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    value:
                                                      _vm.displayDateRetirementDate,
                                                    placeholder: _vm.FormMSG(
                                                      1295,
                                                      "DD/MM/YYYY"
                                                    ),
                                                  },
                                                  on: {
                                                    input: (e) =>
                                                      _vm.updateDate(
                                                        e,
                                                        "retirementDate"
                                                      ),
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "divider" }),
                                        _c("div", { staticClass: "mt-1" }, [
                                          _vm._v(" "),
                                        ]),
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(1706, "Study"))
                                          ),
                                        ]),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                262,
                                                "Study level"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  266,
                                                  "Enter your study level ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.otherStudyLevel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "otherStudyLevel",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.otherStudyLevel",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "divider" }),
                                        _c("div", { staticClass: "mt-1" }, [
                                          _vm._v(" "),
                                        ]),
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(1707, "Clothes"))
                                          ),
                                        ]),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(263, "Size"),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  267,
                                                  "Enter your size ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.size,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "size",
                                                    $$v
                                                  )
                                                },
                                                expression: "myProfile.size",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                264,
                                                "Pant size"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  268,
                                                  "Enter your pant size ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.pantSize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "pantSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.pantSize",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                265,
                                                "Jacket size"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  269,
                                                  "Enter your jacket size ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.jacketSize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "jacketSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.jacketSize",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                27,
                                                "Shoe size"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  28,
                                                  "Enter your shoe size ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.shoeSize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "shoeSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.shoeSize",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                29,
                                                "Teeshirt size"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  30,
                                                  "Enter your teeshirt size ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.tShirtSize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "tShirtSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.tShirtSize",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "settings"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "settings" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(1198, "Language"))
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1209,
                                            "Select a communication language"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.optionsForLanguages,
                                          },
                                          model: {
                                            value: _vm.myProfile.language,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "language",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.language",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("h2", { staticClass: "mt-3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(22, "Change Password")
                                        )
                                      ),
                                    ]),
                                    _c("p", { staticClass: "mt-3" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              107,
                                              "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          size: "lg",
                                          variant: "outline-primary",
                                          disabled:
                                            _vm.statusSendCodeValidation.global,
                                          block: "",
                                        },
                                        on: { click: _vm.handleChangePassword },
                                      },
                                      [
                                        !_vm.statusSendCodeValidation
                                          .progress &&
                                        !_vm.statusSendCodeValidation.success
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      22,
                                                      "Change Password"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.statusSendCodeValidation.progress &&
                                        !_vm.statusSendCodeValidation.success
                                          ? _c(
                                              "span",
                                              [
                                                _c("b-spinner", {
                                                  attrs: {
                                                    label: "Loading...",
                                                    small: "",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        146,
                                                        "Send code validation"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.statusSendCodeValidation
                                          .progress &&
                                        _vm.statusSendCodeValidation.success
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      147,
                                                      "Code validation sended"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "team-session" &&
                          _vm.isDeterProject === true
                            ? _c("div", [
                                _c("div", { staticClass: "settings" }, [
                                  _c("p", { staticClass: "fw-400" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            814,
                                            "Here the list of sessions and teams"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-table-simple",
                                        {
                                          attrs: {
                                            id: "deterSessionTeamTable",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-thead",
                                            {
                                              attrs: { "head-variant": "dark" },
                                            },
                                            [
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th"),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          465,
                                                          "Team A"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          466,
                                                          "Team B"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tbody",
                                            [
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            457,
                                                            "Session 1"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session1A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session1A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session1A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session1B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session1B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session1B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            458,
                                                            "Session 2"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session2A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session2A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session2A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session2B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session2B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session2B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            459,
                                                            "Session 3"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session3A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session3A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session3A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session3B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session3B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session3B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            460,
                                                            "Session 4"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session4A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session4A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session4A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session4B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session4B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session4B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            461,
                                                            "Session 5"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session5A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session5A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session5A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session5B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session5B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session5B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            462,
                                                            "Session 6"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session6A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session6A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session6A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session6B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session6B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session6B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            463,
                                                            "Session 7"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session7A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session7A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session7A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session7B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session7B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session7B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            464,
                                                            "Session 8"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session8A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session8A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session8A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session8B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session8B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session8B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile !== ""
                            ? _c(
                                "b-row",
                                { staticClass: "footer-fixed" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: {
                                            "font-size": "16px",
                                            "font-weight": "700",
                                          },
                                          attrs: {
                                            variant: "primary",
                                            type: "submit",
                                            block: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1210,
                                                  "Save Changes"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("loading", {
                    attrs: { active: _vm.isLoading, "is-full-page": true },
                    on: {
                      "update:active": function ($event) {
                        _vm.isLoading = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "animated fadeIn" },
                [
                  _c("b-row", [
                    _c("div", { staticClass: "container-layout" }, [
                      _c(
                        "div",
                        { staticClass: "card py-4" },
                        [
                          _vm.isDeterProject && _vm.isDeterAction
                            ? _c(
                                "div",
                                { staticClass: "p-2" },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      attrs: {
                                        variant: "warning",
                                        show: "",
                                        dismissible: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center flex-row",
                                          staticStyle: { gap: "10px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                _vm.getIconLucide(
                                                  _vm.ICONS.ALERT_TRIANGLE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#ffc107",
                                                    size: 22,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(1708, "Dear")
                                                ) +
                                                " " +
                                                _vm._s(_vm.userNameActif) +
                                                ", " +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1709,
                                                    "Please add"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.messageContext)
                                              ),
                                            ]),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1710,
                                                    "information in your profile. Thank you for using TheGreenShot services."
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("b-form", { on: { submit: _vm.submitForm } }, [
                            _c(
                              "div",
                              { staticClass: "card-collapsible" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader(
                                          "personnal-data"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                          },
                                          attrs: { icon: "person-check-fill" },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(3, "Personal data")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["personnal-data"]
                                          .isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["personnal-data"]
                                          .isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["personnal-data"]
                                          .isVisible,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "8",
                                                  md: "8",
                                                  lg: "9",
                                                  xl: "9",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "reference",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      this.FormMSG(
                                                                        1,
                                                                        "Reference"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "reference",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    2,
                                                                    "Enter your personal number."
                                                                  ),
                                                                disabled: "",
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .reference,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "reference",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.reference",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  42,
                                                                  "Email"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-input-group",
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "is-invalid":
                                                                        _vm.isSubmitted &&
                                                                        _vm.$v
                                                                          .myProfile
                                                                          .email
                                                                          .$error,
                                                                    },
                                                                    staticStyle:
                                                                      {
                                                                        "border-right":
                                                                          "none",
                                                                      },
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .email,
                                                                      type: "text",
                                                                      id: "email",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1102,
                                                                          "Enter your email"
                                                                        ),
                                                                      disabled:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _vm
                                                                      .myProfile
                                                                      .email &&
                                                                    _vm
                                                                      .myProfile
                                                                      .email
                                                                      .length >
                                                                      0
                                                                      ? _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "border-radius":
                                                                                  "0",
                                                                                "background-color":
                                                                                  "#d5d6d7",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                "Check"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#007A77",
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        staticStyle:
                                                                          {
                                                                            "background-color":
                                                                              "#00a09c",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.showChangeMailModal = true
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Pencil"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#FFFFFF",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.isSubmitted &&
                                                                !_vm.$v
                                                                  .myProfile
                                                                  .email
                                                                  .emailValid
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "invalid-feedback",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                252,
                                                                                "Please, enter valid email"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  44,
                                                                  "Phone"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-input-group",
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "border-right":
                                                                          "none",
                                                                      },
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .phone,
                                                                      type: "text",
                                                                      id: "phone",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          45,
                                                                          "Enter your phone number"
                                                                        ),
                                                                      disabled:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "border-radius":
                                                                              "0",
                                                                            "background-color":
                                                                              "#d5d6d7",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Check"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#007A77",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        staticStyle:
                                                                          {
                                                                            "background-color":
                                                                              "#00a09c",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.showChangePhoneModal = true
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Pencil"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#FFFFFF",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "firstname",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        41,
                                                                        "First Name"
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.isSubmitted &&
                                                                  _vm.$v
                                                                    .myProfile
                                                                    .firstName
                                                                    .$error,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id: "firstname",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    1101,
                                                                    "Enter your first name."
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .firstName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "firstName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.firstName",
                                                              },
                                                            }),
                                                            _vm.isSubmitted &&
                                                            !_vm.$v.myProfile
                                                              .firstName
                                                              .required
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            251,
                                                                            "Please, length should be at least 2"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "name",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        40,
                                                                        "Last Name"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.isSubmitted &&
                                                                  _vm.$v
                                                                    .myProfile
                                                                    .name
                                                                    .$error,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id: "name",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    1100,
                                                                    "Enter your name."
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .name,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "name",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.name",
                                                              },
                                                            }),
                                                            _vm.isSubmitted &&
                                                            !_vm.$v.myProfile
                                                              .name.required
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            251,
                                                                            "Please, length should be at least 2"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "maidenName",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1239,
                                                                      " Maiden name (if different)"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "maidenName",
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .maidenName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "maidenName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.maidenName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center",
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  525,
                                                                  "Color"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "w-100 d-flex justify-content-start",
                                                              },
                                                              [
                                                                _c("verte", {
                                                                  attrs: {
                                                                    picker:
                                                                      "square",
                                                                    model:
                                                                      "hex",
                                                                    menuPosition:
                                                                      "top",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .myProfile
                                                                        .color,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.myProfile,
                                                                          "color",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "myProfile.color",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "nationality",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      46,
                                                                      "Nationality"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "nationality",
                                                                placeholder:
                                                                  this.FormMSG(
                                                                    47,
                                                                    "Enter your nationality"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .nationality,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "nationality",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.nationality",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  80,
                                                                  "Langue"
                                                                ),
                                                              "label-for":
                                                                "optionsForLanguages",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.optionsForLanguages,
                                                                  id: "optionsForLanguages",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .language,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "language",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.language",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  164,
                                                                  "Gender"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .gender,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "gender",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.gender",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.menuGenders,
                                                                function (
                                                                  gender,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "b-form-select-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        value:
                                                                          gender.value,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            gender.text
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  1240,
                                                                  "Title"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .title,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "title",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.title",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.menuTitle,
                                                                function (
                                                                  title,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "b-form-select-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        value:
                                                                          title.value,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            title.text
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  48,
                                                                  "Birthdate"
                                                                ),
                                                              "label-for":
                                                                "birthDate",
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                value:
                                                                  _vm.displayBirthDate,
                                                                type: "date",
                                                                id: "birthDate",
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.updateBirthDate,
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  159,
                                                                  "Place of birth"
                                                                ),
                                                              "label-for":
                                                                "placeOfBirth",
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id: "placeOfBirth",
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    161,
                                                                    "Place of birthstartCapture"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .placeOfBirth,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "placeOfBirth",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.placeOfBirth",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  160,
                                                                  "Place of birth ZIP"
                                                                ),
                                                              "label-for":
                                                                "placeOfBirthZip",
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id: "placeOfBirthZip",
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    162,
                                                                    "Place of birth zip"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .placeOfBirthZip,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "placeOfBirthZip",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.placeOfBirthZip",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  1241,
                                                                  "Country of birth"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    1315,
                                                                    "Enter your country of ..."
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .birthCountry,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "birthCountry",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.birthCountry",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: {
                                                  sm: "4",
                                                  md: "4",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-picture-block",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      "align-items": "center",
                                                      "justify-content":
                                                        "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .picture,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "picture",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.picture",
                                                        },
                                                      },
                                                      [
                                                        _vm.myProfile.picture &&
                                                        _vm.myProfile.picture
                                                          .length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  position:
                                                                    "relative",
                                                                  height:
                                                                    "100%",
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-img",
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer",
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "justify-content":
                                                                            "center",
                                                                          "align-items":
                                                                            "center",
                                                                          width:
                                                                            "175px",
                                                                          height:
                                                                            "175px",
                                                                          "border-radius":
                                                                            "50%",
                                                                          border:
                                                                            "1px solid #cad0d7",
                                                                          "background-color":
                                                                            "#f5f7f9",
                                                                        },
                                                                      attrs: {
                                                                        rounded:
                                                                          "circle",
                                                                        alt: "My picture",
                                                                        src: _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .picture,
                                                                          "picture"
                                                                        ),
                                                                        title:
                                                                          _vm.FormMSG(
                                                                            254,
                                                                            "View Image"
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showImage(
                                                                              _vm
                                                                                .myProfile
                                                                                .picture
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "b-img",
                                                                    _vm.imgPropPicture,
                                                                    false
                                                                  )
                                                                ),
                                                                _vm.myProfile
                                                                  .picture &&
                                                                _vm.myProfile
                                                                  .picture
                                                                  .length > 0
                                                                  ? _c(
                                                                      _vm.getIconLucide(
                                                                        "XCircle"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "cursor-pointer icon-remove-img-profile",
                                                                        staticStyle:
                                                                          {
                                                                            bottom:
                                                                              "85%",
                                                                            right:
                                                                              "10%",
                                                                          },
                                                                        attrs: {
                                                                          size: 32,
                                                                          "stroke-width": 1.75,
                                                                          color:
                                                                            "#ea4e2c",
                                                                          id: "tooltip-remove-picture",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteImg(
                                                                                "picture"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.myProfile
                                                                  .picture &&
                                                                _vm.myProfile
                                                                  .picture
                                                                  .length > 0
                                                                  ? _c(
                                                                      "b-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          target:
                                                                            "tooltip-remove-picture",
                                                                          triggers:
                                                                            "hover",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                255,
                                                                                "Remove picture"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "avatar",
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "justify-content":
                                                                    "center",
                                                                  "align-items":
                                                                    "center",
                                                                  width:
                                                                    "175px",
                                                                  height:
                                                                    "175px",
                                                                  "border-radius":
                                                                    "50%",
                                                                  border:
                                                                    "1px solid #cad0d7",
                                                                  "background-color":
                                                                    "#f5f7f9",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "User"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "vertical-center",
                                                                    attrs: {
                                                                      fill: "#E2E6EB",
                                                                      color:
                                                                        "#E2E6EB",
                                                                      size: 120,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        !_vm.$isPwa()
                                                          ? _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "outline-secondary",
                                                                  size: "md",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.startCapture(
                                                                        "picture"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-camera",
                                                                }),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        43,
                                                                        "Change your picture"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$isPwa()
                                                          ? _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "crop-type":
                                                                    "avatar",
                                                                  "label-button":
                                                                    _vm.FormMSG(
                                                                      43,
                                                                      "Change your picture"
                                                                    ),
                                                                  "parent-type":
                                                                    "user",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.startCapture(
                                                                        "picture"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.isFilmSingle
                                                  ? _c("ShareInformation", {
                                                      attrs: {
                                                        "btn-label":
                                                          _vm.FormMSG(
                                                            169,
                                                            "Share my Information"
                                                          ),
                                                        "title-modal":
                                                          _vm.FormMSG(
                                                            170,
                                                            "Send your personal informations sheet"
                                                          ),
                                                        "msg-success":
                                                          _vm.FormMSG(
                                                            250,
                                                            "Profile successfully sent"
                                                          ),
                                                        "send-a-copy": "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader(
                                          "identification"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c(_vm.getLucideIcon("ClipboardList"), {
                                          tag: "component",
                                          attrs: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                            size: 22,
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(4, "Identification")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["identification"]
                                          .isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["identification"]
                                          .isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["identification"]
                                          .isVisible,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              5,
                                              "Identification Numbers"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        55,
                                                        "National Registration Number"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            56,
                                                            "Enter your National Registration Number "
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .nationalRegistryNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "nationalRegistryNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.nationalRegistryNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "companyNumber",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              57,
                                                              "Company Number"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "companyNumber",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            58,
                                                            "Enter your Company official Number "
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .companyNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "companyNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.companyNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "conge_spectacles_id",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1242,
                                                              '"Congés spectacles" ID'
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "conge_spectacles_id",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1243,
                                                            "Enter your conges spectacles id"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .congesSpectacle,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "congesSpectacle",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.congesSpectacle",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "signatureId",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              223,
                                                              "My signature"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .signatureId
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .signatureId,
                                                                          "signatureId"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded:
                                                                      "circle",
                                                                    alt: "My signature",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .signatureId,
                                                                      "signatureId"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .signatureId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "signatureId"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .signatureId &&
                                                        _vm.myProfile
                                                          .signatureId.length >
                                                          0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "signatureId"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "idNumber",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              216,
                                                              "ID Name"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "idNumber",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            217,
                                                            "Enter your ID Name ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile.idName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "idName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.idName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "idNumber",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              7,
                                                              "ID Number"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "idNumber",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            8,
                                                            "Enter your ID Number ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .idNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "idNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.idNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "pictureId",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              218,
                                                              "ID picture - Front"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureId
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureId,
                                                                          "pictureId"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded:
                                                                      "circle",
                                                                    alt: "ID picture - front",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureId,
                                                                      "pictureId"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "pictureId"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .pictureId &&
                                                        _vm.myProfile.pictureId
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureId"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "pictureIdVerso",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              219,
                                                              "ID picture - Back"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureIdVerso
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureIdVerso,
                                                                          "pictureIdVerso"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded:
                                                                      "circle",
                                                                    alt: "ID picture - back",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureIdVerso,
                                                                      "pictureIdVerso"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureIdVerso
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureIdVerso,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "pictureIdVerso"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .pictureIdVerso &&
                                                        _vm.myProfile
                                                          .pictureIdVerso
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureIdVerso"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            166,
                                                            "Driver licence"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            167,
                                                            "Enter your driver license"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .driverLicense,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "driverLicense",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.driverLicense",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1314,
                                                            "Driver licence Number"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1316,
                                                            "Enter your driver license number"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .driverLicenseNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "driverLicenseNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.driverLicenseNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "pictureDriverLicense",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              221,
                                                              "Driver license picture - Front"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureDriverLicense
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicense,
                                                                          "pictureDriverLicense"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "Driver license picture - Front",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureDriverLicense,
                                                                      "pictureDriverLicense"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicense
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureDriverLicense,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "pictureDriverLicense"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .pictureDriverLicense &&
                                                        _vm.myProfile
                                                          .pictureDriverLicense
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureDriverLicense"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "idNumber",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              222,
                                                              "Driver license picture - Back"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureDriverLicenseVerso
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicenseVerso,
                                                                          "pictureDriverLicenseVerso"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "Driver license picture - Back",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureDriverLicenseVerso,
                                                                      "pictureDriverLicenseVerso"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicenseVerso
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureDriverLicenseVerso,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "pictureDriverLicenseVerso"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .pictureDriverLicenseVerso &&
                                                        _vm.myProfile
                                                          .pictureDriverLicenseVerso
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "pictureDriverLicenseVerso"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader("finance")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c(_vm.getLucideIcon("Wallet"), {
                                          tag: "component",
                                          attrs: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                            size: 22,
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(1244, "Finance"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["finance"]
                                          .isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["finance"].isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["finance"]
                                          .isVisible,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(9, "Bank information")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    class: {
                                                      "is-warning":
                                                        _vm.userBankWarning
                                                          .iban,
                                                    },
                                                    attrs: {
                                                      "label-for": "iban",
                                                      label: _vm.FormMSG(
                                                        49,
                                                        "IBAN"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "iban",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            50,
                                                            "Enter your bank account number"
                                                          ),
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.handleSupplierIbanChange,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile.iban,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "iban",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.iban",
                                                      },
                                                    }),
                                                    _vm.userBankWarning.iban
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "warning-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    97986,
                                                                    "IBAN seems not valid, please make sure it's corrrect"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    class: {
                                                      "is-warning":
                                                        _vm.userBankWarning.bic,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "bic" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              51,
                                                              "BIC"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "bic",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            52,
                                                            "Enter your bank BIC"
                                                          ),
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.handleSupplierBicChange,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile.bic,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "bic",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.bic",
                                                      },
                                                    }),
                                                    _vm.userBankWarning.bic
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "warning-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    909097986,
                                                                    "BIC seems not valid, please make sure it's corrrect"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "bic" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1245,
                                                              "Bank account owner name"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "bic",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1246,
                                                            "Enter your bank account owner name"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .bankAccountOwnerName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "bankAccountOwnerName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.bankAccountOwnerName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "bankDetailDocument",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1247,
                                                              "Bank detail document"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .bankDetailXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .bankDetailXid,
                                                                          "bankDetailXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "Bank detail document",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .bankDetailXid,
                                                                      "bankDetailXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .bankDetailXid
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .myProfile
                                                                        .bankDetailXid,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.myProfile,
                                                                          "bankDetailXid",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "myProfile.bankDetailXid",
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "bankDetailXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .bankDetailXid &&
                                                        _vm.myProfile
                                                          .bankDetailXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "bankDetailXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                        _vm.isDeterAction &&
                                                        _vm.myProfile
                                                          .bankDetailXid
                                                          .length === 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-tooltip",
                                                                          rawName:
                                                                            "v-b-tooltip.hover.bottom.html",
                                                                          value:
                                                                            _vm.FormMSG(
                                                                              1750,
                                                                              "Bank detail document is not downloaded"
                                                                            ),
                                                                          expression:
                                                                            "FormMSG(1750, 'Bank detail document is not downloaded')",
                                                                          modifiers:
                                                                            {
                                                                              hover: true,
                                                                              bottom: true,
                                                                              html: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "btn bg-transparent border-0",
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        _vm
                                                                          .ICONS
                                                                          .ALERT_TRIANGLE
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "#ffc107",
                                                                          size: 30,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(260, "Taxes")) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        261,
                                                        "Taxe"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "limit-100",
                                                              rawName:
                                                                "v-limit-100",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            max: "100",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .taxesPercentage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "taxesPercentage",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.taxesPercentage",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              {
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#00a09c",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Percent"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        "#ffffff",
                                                                      size: 16,
                                                                      "stroke-width": 3.5,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "bic" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1248,
                                                              "Tax declaration country"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "taxCountry",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1249,
                                                            "Enter your tax declaration country"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .taxCountry,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "taxCountry",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.taxCountry",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader("address")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                          },
                                          attrs: { icon: "geo-fill" },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(11, "Address"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["address"]
                                          .isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["address"].isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm.myProfile.address
                                  ? _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["address"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "sub-title" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1250,
                                                    "Legal address"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "6",
                                                      xl: "6",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "country",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  106,
                                                                  "Country"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("v-select", {
                                                          attrs: {
                                                            clearable: false,
                                                            options:
                                                              _vm.countries,
                                                            label: "text",
                                                            reduce: (option) =>
                                                              option.value,
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.getCountry,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.countryKey,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.countryKey =
                                                                $$v
                                                            },
                                                            expression:
                                                              "countryKey",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "6",
                                                      xl: "6",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "google-auto-complete",
                                                          {
                                                            ref: "google-search-address",
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  12,
                                                                  "Internet search"
                                                                ),
                                                              country:
                                                                _vm.countryCode,
                                                            },
                                                            on: {
                                                              placechanged: (
                                                                item
                                                              ) =>
                                                                _vm.handleAutoComplete(
                                                                  item,
                                                                  "legalAddress"
                                                                ),
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "10",
                                                      md: "10",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "address",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  100,
                                                                  "Street"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "street",
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                1103,
                                                                "Enter your street"
                                                              ),
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .address.street,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile
                                                                  .address,
                                                                "street",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.address.street",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "2",
                                                      md: "2",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "number",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  101,
                                                                  "Number"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "number",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .address.number,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile
                                                                  .address,
                                                                "number",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.address.number",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "box",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  102,
                                                                  "Box"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "box",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .address.box,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile
                                                                  .address,
                                                                "box",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.address.box",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "zip",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  104,
                                                                  "Postal Code"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "zip",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .address.zip,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile
                                                                  .address,
                                                                "zip",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.address.zip",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "city",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  103,
                                                                  "City"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "city",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .address.city,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile
                                                                  .address,
                                                                "city",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.address.city",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "state",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  105,
                                                                  "State"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "state",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .address.state,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile
                                                                  .address,
                                                                "state",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.address.state",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            model: {
                                              value: _vm.isShowPostalAddress,
                                              callback: function ($$v) {
                                                _vm.isShowPostalAddress = $$v
                                              },
                                              expression: "isShowPostalAddress",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1312,
                                                        "Postal address"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _vm.myProfile.postalAddress
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "content-card-collapsible",
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "country",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          106,
                                                                          "Country"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    clearable: false,
                                                                    options:
                                                                      _vm.countries,
                                                                    label:
                                                                      "text",
                                                                    reduce: (
                                                                      option
                                                                    ) =>
                                                                      option.value,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      _vm.getCountryPostalAddress,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.countryKeyPostalAddress,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.countryKeyPostalAddress =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "countryKeyPostalAddress",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "google-auto-complete",
                                                                  {
                                                                    ref: "google-search-address",
                                                                    attrs: {
                                                                      label:
                                                                        _vm.FormMSG(
                                                                          12,
                                                                          "Internet search"
                                                                        ),
                                                                      country:
                                                                        _vm.countryCodePostalAddress,
                                                                    },
                                                                    on: {
                                                                      placechanged:
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          _vm.handleAutoComplete(
                                                                            item,
                                                                            "postalAddress"
                                                                          ),
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "10",
                                                              md: "10",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "address",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          100,
                                                                          "Street"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "street",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1103,
                                                                          "Enter your street"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .postalAddress
                                                                          .street,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress,
                                                                            "street",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.postalAddress.street",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "2",
                                                              md: "2",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "number",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          101,
                                                                          "Number"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "number",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .postalAddress
                                                                          .number,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress,
                                                                            "number",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.postalAddress.number",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "box",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          102,
                                                                          "Box"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "box",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .postalAddress
                                                                          .box,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress,
                                                                            "box",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.postalAddress.box",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "zip",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          104,
                                                                          "Postal Code"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "zip",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .postalAddress
                                                                          .zip,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress,
                                                                            "zip",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.postalAddress.zip",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "city",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          103,
                                                                          "City"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "city",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .postalAddress
                                                                          .city,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress,
                                                                            "city",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.postalAddress.city",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "state",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          105,
                                                                          "State"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "state",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .postalAddress
                                                                          .state,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress,
                                                                            "state",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.postalAddress.state",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("b-row", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "28px",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto",
                                                  staticStyle: {
                                                    height: "40px",
                                                    "border-radius": "0.5rem",
                                                    "background-color": "#fff",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    size: "sm",
                                                    variant:
                                                      _vm.isShowPostalAddress
                                                        ? "outline-danger"
                                                        : "outline-secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.isShowPostalAddress =
                                                        !_vm.isShowPostalAddress
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row align-items-center justify-content-center btn-address",
                                                      staticStyle: {
                                                        color: "#06263e",
                                                      },
                                                    },
                                                    [
                                                      _vm.isShowPostalAddress
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .X_CLEAR
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .X_CLEAR
                                                                        .color,
                                                                    size: 22,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1313,
                                                                      "Close"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "PlusCircle"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      "#47C7BF",
                                                                    size: 22,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1251,
                                                                      "Add postal address (if different)"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader("health")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c(_vm.getLucideIcon("HeartPulse"), {
                                          tag: "component",
                                          attrs: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                            size: 22,
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(1252, "Health"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["health"].isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["health"].isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["health"].isVisible,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "4",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        54,
                                                        "Social Security Number"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      class: {
                                                        "is-warning":
                                                          _vm.isSocSecNumberWarning,
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            154,
                                                            "Enter your Social Security Number"
                                                          ),
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.handleSocSecNumberChange,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .socSecNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "socSecNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.socSecNumber",
                                                      },
                                                    }),
                                                    _vm.isSocSecNumberWarning
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "warning-feedback",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    8979997,
                                                                    "The social security number seems not valid"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "4",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "socialSecurityCardXid",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1255,
                                                              "Social security card"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .socialSecurityCardXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .socialSecurityCardXid,
                                                                          "socialSecurityCardXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "socialSecurityCardXid",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .socialSecurityCardXid,
                                                                      "socialSecurityCardXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .socialSecurityCardXid
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "socialSecurityCardXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .socialSecurityCardXid &&
                                                        _vm.myProfile
                                                          .socialSecurityCardXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "socialSecurityCardXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "4",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "inscuranceCertificate",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1256,
                                                              "Insurance certificate"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .insuranceCertificateXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .insuranceCertificateXid,
                                                                          "insuranceCertificateXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "inscuranceCertificate",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .insuranceCertificateXid,
                                                                      "insuranceCertificateXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .insuranceCertificateXid
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "insuranceCertificateXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .insuranceCertificateXid &&
                                                        _vm.myProfile
                                                          .insuranceCertificateXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "insuranceCertificateXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "4",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "socialSecurityCenter",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1700,
                                                              "Social security center"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "socialSecurityCenter",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1258,
                                                            "Enter your social security center"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .socialSecurityCenter,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "socialSecurityCenter",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.socialSecurityCenter",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "8",
                                                  xl: "8",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1259,
                                                        "Social security center address"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("v-address", {
                                                      attrs: {
                                                        params: {
                                                          title:
                                                            "Social security center address",
                                                        },
                                                        "address-id":
                                                          _vm.myProfile
                                                            .socialSecurityCenterAddressId,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.handleDynamicAddressChange,
                                                        delete:
                                                          _vm.handleDynamicAddressDelete,
                                                        "address-maplink-change":
                                                          _vm.handleAddressMapLinkChange,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1260,
                                                            "Last medical visit date"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "date",
                                                        value:
                                                          _vm.displayDateLastMedicalVisit,
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1295,
                                                            "DD/MM/YYYY"
                                                          ),
                                                      },
                                                      on: {
                                                        input: (e) =>
                                                          _vm.updateDate(
                                                            e,
                                                            "lastMedicalVisit"
                                                          ),
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1262,
                                                            "Medical visit validity"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "date",
                                                        value:
                                                          _vm.displayDateMedicalVisitValidity,
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1295,
                                                            "DD/MM/YYYY"
                                                          ),
                                                      },
                                                      on: {
                                                        input: (e) =>
                                                          _vm.updateDate(
                                                            e,
                                                            "medicalVisitValidity"
                                                          ),
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "medicalVisitDocument",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1264,
                                                              "Medical visit document"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .medicalVisitDocumentXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .medicalVisitDocumentXid,
                                                                          "medicalVisitDocumentXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "medicalVisitDocument",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .medicalVisitDocumentXid,
                                                                      "medicalVisitDocumentXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .medicalVisitDocumentXid
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .myProfile
                                                                        .medicalVisitDocumentXid,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.myProfile,
                                                                          "medicalVisitDocumentXid",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "myProfile.medicalVisitDocumentXid",
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "medicalVisitDocumentXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .medicalVisitDocumentXid &&
                                                        _vm.myProfile
                                                          .medicalVisitDocumentXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "medicalVisitDocumentXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                        _vm.isDeterAction &&
                                                        _vm.myProfile
                                                          .medicalVisitDocumentXid
                                                          .length === 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-tooltip",
                                                                          rawName:
                                                                            "v-b-tooltip.hover.bottom.html",
                                                                          value:
                                                                            _vm.FormMSG(
                                                                              1751,
                                                                              "Medical visit document is not downloaded"
                                                                            ),
                                                                          expression:
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tFormMSG(1751, 'Medical visit document is not downloaded')\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                          modifiers:
                                                                            {
                                                                              hover: true,
                                                                              bottom: true,
                                                                              html: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "btn bg-transparent border-0",
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        _vm
                                                                          .ICONS
                                                                          .ALERT_TRIANGLE
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "#ffc107",
                                                                          size: 30,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  { staticClass: "text-start" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1265,
                                                            "Worker with a disability"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start",
                                                        attrs: {
                                                          size: "lg",
                                                          switch: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .disabled,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "disabled",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.disabled",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-redired",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.myProfile
                                                                  .disabled
                                                                  ? _vm.FormMSG(
                                                                      1276,
                                                                      "Yes"
                                                                    )
                                                                  : _vm.FormMSG(
                                                                      1277,
                                                                      "No"
                                                                    )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1266,
                                                                "Disability notification date"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "date",
                                                            value:
                                                              _vm.displayDateDisabilityNotification,
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                1295,
                                                                "DD/MM/YYYY"
                                                              ),
                                                          },
                                                          on: {
                                                            input: (e) =>
                                                              _vm.updateDate(
                                                                e,
                                                                "disabilityNotification"
                                                              ),
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "type",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  1268,
                                                                  "Type"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "type",
                                                            placeholder:
                                                              this.FormMSG(
                                                                1269,
                                                                "Enter type"
                                                              ),
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .disabilityType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "disabilityType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.disabilityType",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "status",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  1270,
                                                                  "Status"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            id: "status",
                                                            placeholder:
                                                              this.FormMSG(
                                                                1271,
                                                                "Enter status"
                                                              ),
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .disabilityStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "disabilityStatus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.disabilityStatus",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          label: _vm.FormMSG(
                                                            1272,
                                                            "Invalidity"
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-select",
                                                          {
                                                            model: {
                                                              value:
                                                                _vm.myProfile
                                                                  .invalidity,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.myProfile,
                                                                    "invalidity",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "myProfile.invalidity",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.menuInvalidity,
                                                            function (
                                                              gender,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "b-form-select-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    value:
                                                                      gender.value,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        gender.text
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center mt-2",
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile.rqth,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "rqth",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.rqth",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1273,
                                                                  "RQTH"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          label: _vm.FormMSG(
                                                            1274,
                                                            "Invalidity percentage"
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-input-group",
                                                          [
                                                            _c("b-form-input", {
                                                              directives: [
                                                                {
                                                                  name: "limit-100",
                                                                  rawName:
                                                                    "v-limit-100",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "number",
                                                                step: "1",
                                                                min: "0",
                                                                max: "100",
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .invalidityPourcentage,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "invalidityPourcentage",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.invalidityPourcentage",
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "background-color":
                                                                          "#00a09c",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Percent"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "#ffffff",
                                                                          size: 16,
                                                                          "stroke-width": 3.5,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            25,
                                                            "Have Allergies?"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        size: "lg",
                                                        switch: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.handleChangeHaveAllergies,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.haveAllergies,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.haveAllergies =
                                                            $$v
                                                        },
                                                        expression:
                                                          "haveAllergies",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            26,
                                                            "Allergies details"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("b-form-textarea", {
                                                      attrs: {
                                                        type: "textarea",
                                                        rows: "2",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            6,
                                                            "Enter allergies details..."
                                                          ),
                                                        autocomplete: "off",
                                                        disabled:
                                                          _vm.haveAllergies ===
                                                          false,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .allergies,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "allergies",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.allergies",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader("family")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                          },
                                          attrs: { icon: "people-fill" },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(13, "Family"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["family"].isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["family"].isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["family"].isVisible,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              14,
                                              "Family composition"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "4",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        53,
                                                        "Civil status"
                                                      ),
                                                      "label-for":
                                                        "optionsForCivilStatus",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.optionsForCivilStatus,
                                                        id: "optionsForCivilStatus",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .civilStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "civilStatus",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.civilStatus",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.myProfile.civilStatus === 3
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                174,
                                                                "Spouse first name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                176,
                                                                "Enter your Spouse first name "
                                                              ),
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .spouseFirstName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "spouseFirstName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.spouseFirstName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.civilStatus === 3
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                172,
                                                                "Spouse name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                173,
                                                                "Enter your Spouse name "
                                                              ),
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .spouseName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "spouseName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.spouseName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm.myProfile.civilStatus === 3
                                          ? _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                177,
                                                                "Spouse birth date"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "date",
                                                            value:
                                                              _vm.displaySpouseBirthDate,
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                178,
                                                                "Enter your Spouse birth date "
                                                              ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.updateSpouseBirthDate,
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        staticClass:
                                                          "text-start",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                181,
                                                                "Spouse has salary"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "b-form-checkbox",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-start",
                                                            attrs: {
                                                              size: "lg",
                                                              switch: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.myProfile
                                                                  .spouseHasSalary,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.myProfile,
                                                                    "spouseHasSalary",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "myProfile.spouseHasSalary",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-redired",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .myProfile
                                                                      .spouseHasSalary
                                                                      ? _vm.FormMSG(
                                                                          1276,
                                                                          "Yes"
                                                                        )
                                                                      : _vm.FormMSG(
                                                                          1277,
                                                                          "No"
                                                                        )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                179,
                                                                "Spouse profession"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                180,
                                                                "Enter your Spouse profession "
                                                              ),
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .spouseProfession,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "spouseProfession",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.spouseProfession",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        183,
                                                        "Number of dependent child"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "0",
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDependentChild,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "numberOfDependentChild",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.numberOfDependentChild",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        184,
                                                        "Number of disabled child"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "0",
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDisabledChild,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "numberOfDisabledChild",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.numberOfDisabledChild",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        185,
                                                        "Number of dependent person"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "0",
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDependentPerson,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "numberOfDependentPerson",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.numberOfDependentPerson",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        186,
                                                        "Number of disabled person"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        placeholder: "0",
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .numberOfDisabledPerson,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "numberOfDisabledPerson",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.numberOfDisabledPerson",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(15, "Emergency contact")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        188,
                                                        "Person to contact"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            189,
                                                            "Enter person to contact"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .personToContact,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "personToContact",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.personToContact",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        190,
                                                        "Person to contact phone"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            191,
                                                            "Enter person to contact phone"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .personToContactPhone,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "personToContactPhone",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.personToContactPhone",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.myProfile.vehicule
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "personal-vehicle"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c("span", [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "1em",
                                                    height: "1.15em",
                                                    fill: "rgba(6, 38, 62, 0.84)",
                                                    viewBox: "0 0 16 16",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1226,
                                                      "Personal vehicle"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-10",
                                          },
                                          [
                                            !_vm.configAccordion[
                                              "personal-vehicle"
                                            ].isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion[
                                              "personal-vehicle"
                                            ].isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion[
                                              "personal-vehicle"
                                            ].isVisible,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c("vehicule-form", {
                                              attrs: {
                                                "vehicule-data":
                                                  _vm.myProfile.vehicule,
                                              },
                                              on: {
                                                "vehicule-form:change":
                                                  _vm.handleVehiculeFormChange,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader("other")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                          },
                                          attrs: { icon: "list" },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(16, "Other"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["other"].isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["other"].isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["other"].isVisible,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(17, "Privacy options")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: { size: "lg" },
                                                    model: {
                                                      value:
                                                        _vm.myProfile
                                                          .showMyEmailInCrew,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "showMyEmailInCrew",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.showMyEmailInCrew",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          18,
                                                          "Show my email in crew list"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: { size: "lg" },
                                                    model: {
                                                      value:
                                                        _vm.myProfile
                                                          .showMyPhoneInCrew,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "showMyPhoneInCrew",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.showMyPhoneInCrew",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          19,
                                                          "Show my phone in crew list"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(1280, "Loyalty cards")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1281,
                                                                        "SNCF loyalty card"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-fin",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1282,
                                                                          "(Type + number)"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1283,
                                                            "Enter your sncf loyalty card"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .trainLoyaltyCard,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "trainLoyaltyCard",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.trainLoyaltyCard",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1284,
                                                                        "SNCF discount card"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-fin",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1282,
                                                                          "(Type + number)"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1285,
                                                            "Enter your sncf discount card"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .trainDiscountCard,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "trainDiscountCard",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.trainDiscountCard",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1286,
                                                                        "Flying blue card"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-fin",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1287,
                                                                          "(Number)"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1288,
                                                            "Enter your flying blue card"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .flyingLoyaltyCard,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "flyingLoyaltyCard",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.flyingLoyaltyCard",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1289,
                                                                        "Other airline card"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-fin",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1287,
                                                                          "(Number)"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1288,
                                                            "Enter your other airline card"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .otherAirlineCard,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "otherAirlineCard",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.otherAirlineCard",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(1290, "Foreign worker")
                                          )
                                      ),
                                      _c("span", { staticClass: "text-fin" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(
                                              1291,
                                              "(to fill only if you're a foraign worker)"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1292,
                                                        "Residence permit number"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1293,
                                                            "Enter your residence permit number"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .residencePermitNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "residencePermitNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.residencePermitNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1294,
                                                        "Residence permit validity end"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "date",
                                                        value:
                                                          _vm.displayDateResidencePermitEndValidity,
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1295,
                                                            "DD/MM/YYYY"
                                                          ),
                                                      },
                                                      on: {
                                                        input: (e) =>
                                                          _vm.updateDate(
                                                            e,
                                                            "residencePermitEndValidity"
                                                          ),
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "residentPermitXid",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1296,
                                                              "Resident permit"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .residentPermitXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .residentPermitXid,
                                                                          "residentPermitXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "residentPermitXid",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .residentPermitXid,
                                                                      "residentPermitXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .residentPermitXid
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "residentPermitXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .residentPermitXid &&
                                                        _vm.myProfile
                                                          .residentPermitXid
                                                          .length > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "residentPermitXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "workCardXid",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1297,
                                                              "Work card"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .workCardXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .workCardXid,
                                                                          "workCardXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale":
                                                                      "2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "workCardXid",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .workCardXid,
                                                                      "workCardXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .workCardXid
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.startCapture(
                                                                  "workCardXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Camera"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pl-2",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "-1px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          220,
                                                                          "Picture"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.myProfile
                                                          .workCardXid &&
                                                        _vm.myProfile
                                                          .workCardXid.length >
                                                          0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent pl-0 ml-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteImg(
                                                                        "workCardXid"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Trash2"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 24,
                                                                      "stroke-width": 1.5,
                                                                      color:
                                                                        "#ea4e2c",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(24, "Miscellanous")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1298,
                                                        "Pseudonym"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1299,
                                                            "Enter your pseudonym"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .pseudonym,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "pseudonym",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.pseudonym",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "4",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1300,
                                                        "Generic mention professional association"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1301,
                                                            "Enter your generic mention ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .professionalAssociation,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "professionalAssociation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.professionalAssociation",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(1705, "Retirement"))
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1275,
                                                            "Retired ?"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start",
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          switch: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .retired,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "retired",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.retired",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-redired",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.myProfile
                                                                  .retired
                                                                  ? _vm.FormMSG(
                                                                      1276,
                                                                      "Yes"
                                                                    )
                                                                  : _vm.FormMSG(
                                                                      1277,
                                                                      "No"
                                                                    )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.myProfile.retired
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "12",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1278,
                                                                "Retirement date"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "date",
                                                            value:
                                                              _vm.displayDateRetirementDate,
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                1295,
                                                                "DD/MM/YYYY"
                                                              ),
                                                          },
                                                          on: {
                                                            input: (e) =>
                                                              _vm.updateDate(
                                                                e,
                                                                "retirementDate"
                                                              ),
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(1706, "Study"))
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        262,
                                                        "Study level"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            266,
                                                            "Enter your study level ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .otherStudyLevel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "otherStudyLevel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.otherStudyLevel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(1707, "Clothes"))
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        263,
                                                        "Size"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            267,
                                                            "Enter your size ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile.size,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "size",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.size",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        264,
                                                        "Pant size"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            268,
                                                            "Enter your pant size ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .pantSize,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "pantSize",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.pantSize",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        265,
                                                        "Jacket size"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            269,
                                                            "Enter your jacket size ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .jacketSize,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "jacketSize",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.jacketSize",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        27,
                                                        "Shoe size"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            28,
                                                            "Enter your shoe size ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .shoeSize,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "shoeSize",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.shoeSize",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "3",
                                                  xl: "3",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        29,
                                                        "Teeshirt size"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            30,
                                                            "Enter your teeshirt size ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .tShirtSize,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "tShirtSize",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.tShirtSize",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-collapsible mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader("password")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                          },
                                          attrs: { icon: "key-fill" },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(31, "Password"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-10",
                                      },
                                      [
                                        !_vm.configAccordion["password"]
                                          .isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["password"]
                                          .isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["password"]
                                          .isVisible,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c("p", { staticClass: "text-muted" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t*\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  107,
                                                  "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                              disabled:
                                                _vm.statusSendCodeValidation
                                                  .global,
                                            },
                                            on: {
                                              click: _vm.handleChangePassword,
                                            },
                                          },
                                          [
                                            !_vm.statusSendCodeValidation
                                              .progress &&
                                            !_vm.statusSendCodeValidation
                                              .success
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          22,
                                                          "Change Password"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.statusSendCodeValidation
                                              .progress &&
                                            !_vm.statusSendCodeValidation
                                              .success
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("b-spinner", {
                                                      attrs: {
                                                        label: "Loading...",
                                                        small: "",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            146,
                                                            "Send code validation"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.statusSendCodeValidation
                                              .progress &&
                                            _vm.statusSendCodeValidation.success
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          147,
                                                          "Code validation sended"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.isDeterProject === true
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "deter"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c(_vm.getLucideIcon("UserCog"), {
                                              tag: "component",
                                              attrs: { size: 20 },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      131,
                                                      "Team session"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-10",
                                          },
                                          [
                                            !_vm.configAccordion["deter"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["deter"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["deter"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-table-simple",
                                              {
                                                attrs: {
                                                  id: "deterSessionTeamTable",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-thead",
                                                  {
                                                    attrs: {
                                                      "head-variant": "dark",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-tr",
                                                      [
                                                        _c("b-th"),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                457,
                                                                "Session 1"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                458,
                                                                "Session 2"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                459,
                                                                "Session 3"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                460,
                                                                "Session 4"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                461,
                                                                "Session 5"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                462,
                                                                "Session 6"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                463,
                                                                "Session 7"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                464,
                                                                "Session 8"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-tbody",
                                                  [
                                                    _c(
                                                      "b-tr",
                                                      [
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                465,
                                                                "Team A"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session1A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session1A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session1A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session2A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session2A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session2A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session3A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session3A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session3A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session4A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session4A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session4A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session5A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session5A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session5A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session6A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session6A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session6A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session7A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session7A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session7A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session8A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session8A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session8A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-tr",
                                                      [
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                466,
                                                                "Team B"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session1B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session1B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session1B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session2B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session2B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session2B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session3B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session3B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session3B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session4B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session4B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session4B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session5B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session5B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session5B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session6B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session6B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session6B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session7B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session7B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session7B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session8B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session8B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session8B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-4 px-2 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-1",
                                    staticStyle: {
                                      "font-size": "1.25rem",
                                      "letter-spacing": "0.05rem",
                                    },
                                    attrs: {
                                      type: "submit",
                                      variant: "primary",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(23, "Save my changes"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("NewCapture", {
                    key: _vm.refreshTokenCaptureItem,
                    attrs: {
                      parentType: "user",
                      parentId: _vm.myProfile.id,
                      "auto-crop-capture": "",
                      "cam-mode-first": "",
                      "only-one-crop-mode": "",
                    },
                    on: { change: _vm.handleImgsTaked },
                    model: {
                      value: _vm.capturePict,
                      callback: function ($$v) {
                        _vm.capturePict = $$v
                      },
                      expression: "capturePict",
                    },
                  }),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-success",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(8979800, "Success!"),
                        "ok-variant": "success",
                        "ok-only": "",
                        "cancel-title": "close",
                        "modal-class": "mui-animation",
                        fade: false,
                      },
                      on: {
                        ok: function ($event) {
                          _vm.successModal = false
                        },
                      },
                      model: {
                        value: _vm.successModal,
                        callback: function ($$v) {
                          _vm.successModal = $$v
                        },
                        expression: "successModal",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              8979799,
                              "Your information has been saved."
                            )
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
      ]),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: "Crop Picture",
            size: "cropSize",
            "ok-variant": "success",
            "ok-title": "Confirm Save",
            "cancel-title": "Cancel",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { ok: _vm.endCrop, cancel: _vm.endCropCancel },
          model: {
            value: _vm.cropPict,
            callback: function ($$v) {
              _vm.cropPict = $$v
            },
            expression: "cropPict",
          },
        },
        [_c("CropPicture", { ref: "myCropPicture" })],
        1
      ),
      _c("change-mail-modal", {
        attrs: { open: _vm.showChangeMailModal, profile: _vm.myProfile },
        on: {
          "modal:close": function ($event) {
            _vm.showChangeMailModal = false
          },
          "change-mail-modal:save": _vm.handleMailModalSave,
        },
      }),
      _c("change-phone-modal", {
        attrs: { open: _vm.showChangePhoneModal, profile: _vm.myProfile },
        on: {
          "modal:close": function ($event) {
            _vm.showChangePhoneModal = false
          },
          "change-phone-modal:save": _vm.handlePhoneModalSave,
        },
      }),
      _c("validation-code-modal", {
        attrs: {
          open: _vm.showValidationCodeModal,
          "email-for-validation": _vm.myProfile.email,
        },
        on: {
          "validation-code-modal:save": _vm.handleValidationCodeModalSave,
          "modal:close": _vm.handleValidationCodeModalClose,
        },
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(253, "Picture"),
            "ok-variant": "success",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: function ($event) {
              _vm.openPictureModal = false
            },
          },
          model: {
            value: _vm.openPictureModal,
            callback: function ($$v) {
              _vm.openPictureModal = $$v
            },
            expression: "openPictureModal",
          },
        },
        [
          _c(
            "div",
            [_c("b-img", { attrs: { src: _vm.imgToShow, "fluid-grow": "" } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }